// src/components/LanguageSwitcher.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation(); // 't' để lấy bản dịch, 'i18n' để thay đổi ngôn ngữ
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(prevState => !prevState);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    return (
        <div className="relative w-48 inline-block text-left">
            <button
                onClick={toggleMenu}
                className="bg-gray-800 dark:bg-white text-white dark:text-gray-800 px-4 py-2 rounded-md focus:outline-none"
            >
                {t('language')}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 inline-block ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M6 9l6 6 6-6"></path>
                </svg>
            </button>

            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <button
                            href="#"
                            onClick={() => changeLanguage('en')}
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                            English (EN)
                        </button>
                        <button
                            href="#"
                            onClick={() => changeLanguage('vi')}
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                            Vietnamese (VI)
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LanguageSwitcher;
