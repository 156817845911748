import React, { useState, useEffect } from 'react';

const Loading = () => {
    const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     // Giả lập thời gian tải dữ liệu
    //     const timer = setTimeout(() => setIsLoading(false), 3000); // 3 giây
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-700">
            {isLoading ? (
                <div className="flex flex-col items-center">
                    <div className="w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
                    <p className="mt-4 text-lg font-semibold text-blue-500">Loading...</p>
                </div>
            ) : (
                <div className="text-center">
                    <h1 className="text-3xl font-bold text-gray-800">Trang đã tải xong!</h1>
                    <p className="mt-2 text-gray-600">Chào mừng bạn đến với trang web của chúng tôi.</p>
                </div>
            )}
        </div>
    );
};

export default Loading;
