import React, { createContext, useState, useEffect } from 'react';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const token = localStorage.getItem('token');
    const [products, setTours] = useState([]);
    const [quocgia, setQG] = useState([]);
    const [theloaidl, setTL] = useState([]);
    const [khachhang, setKH] = useState([]);
    const [khachhangs, setKHS] = useState([]);
    const [accounts, setAcc] = useState([]);
    const [posts, setPosts] = useState([]);
    const [accountData, setAccountData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [tourData, qgData, tlData, accData, khsData] = await Promise.all([
                    fetch(`${process.env.REACT_APP_HOST}/api/tourdl`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_HOST}/api/quocgia`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_HOST}/api/theloaidl`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_HOST}/api/account`).then(res => res.json()),
                    fetch(`${process.env.REACT_APP_HOST}/api/khachhang`).then(res => res.json()),
                ]);

                setTours(tourData);
                setQG(qgData);
                setTL(tlData);
                setAcc(accData);
                setKHS(khsData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}/get-posts`);
                const data = await response.json();
                setPosts(data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    useEffect(() => {
        if (token) {
            const fetchKhachHang = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_HOST}/api/khachhang/login`, {
                        method: 'GET',
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    const data = await response.json();
                    setKH(data);
                } catch (error) {
                    console.error('Error fetching khachhang:', error);
                }
            };

            fetchKhachHang();
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            const fetchAccountData = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_HOST}/api/account/login`, {
                        method: 'GET',
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    if (!response.ok) throw new Error('Error fetching account data');
                    const data = await response.json();
                    setAccountData(data);
                } catch (error) {
                    console.error('Error fetching account data:', error);
                }
            };

            fetchAccountData();
        }
    }, [token]);

    return (
        <ApiContext.Provider value={{
            products, quocgia, theloaidl, khachhang, khachhangs, posts, accountData, accounts,
            setTours, setQG, setTL, setKH, setKHS, setPosts, setAccountData, setAcc,
        }}>
            {children}
        </ApiContext.Provider>
    );
};

export default ApiContext;
