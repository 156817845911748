import { Link } from 'react-router-dom';
import { FaCartPlus } from "react-icons/fa";
import { RiLoginBoxLine } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/react'
import {
    Bars3Icon,
    ChartPieIcon,
    XMarkIcon,
    MapIcon,
    TruckIcon,
    CloudIcon,
    GlobeAsiaAustraliaIcon,
    PencilSquareIcon,
    UserCircleIcon,
    KeyIcon,
    LinkIcon,
    BookmarkIcon,
    ShoppingCartIcon,
    TableCellsIcon,
    ArchiveBoxArrowDownIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect, useContext } from 'react';
import LanguageSwitcher from './locales/LanguageSwitcher';
import { useLocation, useNavigate } from "react-router-dom";
import { TranslationContext } from './locales/TranslationContext';
import { useTranslation } from 'react-i18next';
import DarkModeToggle from './DarkModeToggle';


export default function NavBar({ isAuthenticated, onLogout }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const { t } = useTranslation();
    const tour = [
        {
            name: 'Tour', description: t('nav.Services.Tour'), href: '/properties', icon: GlobeAsiaAustraliaIcon
        },
        { name: t('nav.Services.CarRental'), description: t('nav.Services.CarRental1'), href: '/carcall', icon: TruckIcon },
        { name: t('nav.Services.Weather'), description: t('nav.Services.Weather1'), href: '/weather', icon: CloudIcon },

    ]
    const profile = [
        { name: t('nav.Profile.General'), description: t('nav.Profile.General1'), href: '/profile/account-general', icon: UserCircleIcon },
        { name: t('nav.Profile.ChangePassword'), description: t('nav.Profile.ChangePassword1'), href: '/profile/account-change-password', icon: KeyIcon },
        { name: t('nav.Profile.SocialLinks'), description: t('nav.Profile.SocialLinks1'), href: '/profile/account-social-links', icon: LinkIcon },
        { name: t('nav.Profile.BlogEdit'), description: t('nav.Profile.BlogEdit1'), href: '/profile/account-blog', icon: TableCellsIcon },
        { name: t('nav.Profile.Connections'), description: t('nav.Profile.Connections1'), href: '/profile/account-connections', icon: BookmarkIcon },
        { name: t('nav.Profile.Carts'), description: t('nav.Profile.Carts1'), href: '/profile/account-cart', icon: ShoppingCartIcon },
        { name: t('nav.Profile.Others'), description: t('nav.Profile.Others1'), href: '/profile/account-orders', icon: ArchiveBoxArrowDownIcon },

    ]
    const blog = [
        { name: t('nav.Blog.Blog'), description: t('nav.Blog.Blog1'), href: '/blog', icon: PencilSquareIcon },
    ]
    const callsToAction = [
        { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
        { name: 'Contact sales', href: '#', icon: PhoneIcon },
    ]
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 1); // Điều chỉnh trạng thái navbar khi cuộn
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    ////

    const location = useLocation();
    const [activeLink, setActiveLink] = useState("");
    const navigate = useNavigate();
    const products = JSON.parse(localStorage.getItem('products')) || []; // Chuyển chuỗi JSON thành mảng hoặc gán mảng trống
    const totalProducts = products.length; // Đếm số phần tử trong mảng products


    return (
        <header className={`fixed z-40 w-full transition-all duration-300  ${scrolled ? 'bg-white dark:bg-gray-700 shadow-md' : ''
            }`}>
            <nav aria-label="Global" className={`mx-auto flex max-w-7xl items-center justify-between px-6 lg:px-10 ${mobileMenuOpen ? 'hidden' : ''}`}>
                <a href="/" className="p-1.5 flex justify-center items-center">
                    <img
                        alt=""
                        src="https://s3.tebi.io/image-web-travel/logotravel.png"
                        className="w-20 lg:w-40 aspect-square"
                    />

                </a>
                <div className="flex left-0 lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-10 w-10 dark:text-gray-200" />
                    </button>
                </div>
                <PopoverGroup className="hidden w-full lg:grid grid-flow-col justify-center items-center">
                    <a href="/" className="text-sm text-center w-auto min-w-[5rem] font-semibold leading-6 text-orange-500 hover:scale-[1.3]">
                        {t('nav.Home')}
                    </a>

                    <Popover className="relative">
                        <PopoverButton className="flex items-center justify-center text-center gap-x-1 text-sm font-semibold leading-6 text-orange-500 hover:scale-[1.3] w-auto min-w-[7rem]">
                            {t('nav.Services.Services')}
                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-4">
                                {tour.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                    >
                                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                                        </div>
                                        <div className="flex-auto">
                                            <a href={item.href} className="block font-semibold text-orange-500">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </a>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                {callsToAction.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-orange-500 hover:bg-gray-100"
                                    >
                                        <item.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>

                    <a href="/contact" className="text-sm text-center w-auto min-w-[5rem] font-semibold leading-6 text-orange-500 hover:scale-[1.3]">
                        {t('nav.Contact')}
                    </a>

                    <Popover className="relative">
                        <PopoverButton className="flex items-center justify-center text-center gap-x-1 text-sm font-semibold leading-6 text-orange-500 hover:scale-[1.3] w-auto min-w-[7rem]">
                            {t('nav.Blog.Blog')}
                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-4">
                                {blog.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                    >
                                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                                        </div>
                                        <div className="flex-auto">
                                            <a href={item.href} className="block font-semibold text-orange-500">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </a>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </PopoverPanel>
                    </Popover>

                    {isAuthenticated ? (
                        <>
                            <Popover className="relative">
                                <PopoverButton className="flex items-center justify-center text-center gap-x-1 hover:scale-[1.3] text-sm font-semibold leading-6 text-orange-500 w-auto min-w-[5rem]">
                                    {t('nav.Profile.Profile')}
                                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                                </PopoverButton>

                                <PopoverPanel
                                    transition
                                    className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in max-h-96 overflow-y-auto"
                                >
                                    <div className="p-4">
                                        {profile.map((item) => (
                                            <div
                                                key={item.name}
                                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                            >
                                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                                                </div>
                                                <div className="flex-auto">
                                                    <a href={item.href} className="block font-semibold text-orange-500">
                                                        {item.name}
                                                        <span className="absolute inset-0" />
                                                    </a>
                                                    <p className="mt-1 text-gray-600">{item.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Nổi nhưng vẫn nằm trong Popover */}
                                    <div className="sticky bottom-0 grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 shadow-md rounded-b-3xl">
                                        {callsToAction.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-orange-500 hover:bg-gray-100"
                                            >
                                                <item.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </PopoverPanel>
                            </Popover>

                            <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center justify-center text-center gap-x-1 text-sm font-semibold leading-6 text-orange-500 hover:scale-[1.3] w-auto min-w-[5rem] ">
                                <a href="#" onClick={onLogout} className="flex">
                                    {t('nav.Logout')}<IoLogOutOutline className='text-xl' />
                                </a>
                            </div>
                        </>
                    ) : (
                        <div className="hidden lg:flex lg:flex-1 items-center justify-center text-center gap-x-1 text-sm font-semibold leading-6 text-orange-500 hover:scale-[1.3] w-auto min-w-[7rem]">
                            <a href="/login" className="flex">
                                {t('nav.Login')} <RiLoginBoxLine className='text-xl' />
                            </a>
                        </div>
                    )}

                </PopoverGroup>
                <div className="hidden lg:flex left-0 justify-between items-center gap-x-4">
                    <LanguageSwitcher />
                    <a href="/cart" className="flex font-semibold leading-6 text-orange-500 rounded-full">
                        <FaCartPlus className='text-5xl' />
                        {totalProducts ?
                            <div className=' bg-red-500 flex aspect-square w-1/2 h-1/2 rounded-full items-center justify-center text-white animate-pulse'>{totalProducts}</div>
                            : ''}
                    </a>
                    <DarkModeToggle />
                </div>

            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="p-1.5 flex justify-center items-center">
                            <img
                                alt=""
                                src="https://s3.tebi.io/image-web-travel/logotravel.png"
                                className="w-20 lg:w-40 aspect-square"
                            />

                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 w-full divide-y divide-gray-500/10 flex flex-col justify-between ">
                            <div className="space-y-2 py-6">
                                <a
                                    href='/'
                                    className="-mx-3 block px-3 py-2 rounded-lg text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    <div className=''>{t('nav.Home')}</div>

                                </a>
                                <Disclosure as="div" className="-mx-3">
                                    <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                        {t('nav.Services.Services')}
                                        <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                                    </DisclosureButton>
                                    <DisclosurePanel className="mt-2 space-y-2">
                                        {[...tour, ...callsToAction].map((item) => (
                                            <DisclosureButton
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                {item.name}
                                            </DisclosureButton>
                                        ))}
                                    </DisclosurePanel>
                                </Disclosure>
                                <a
                                    href="/contact"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {t('nav.Contact')}
                                </a>
                                <a
                                    href="/blog"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {t('nav.Blog.Blog')}
                                </a>
                                {isAuthenticated ? (
                                    <Disclosure as="div" className="-mx-3">
                                        <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            {t('nav.Profile.Profile')}
                                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                                        </DisclosureButton>
                                        <DisclosurePanel className="mt-2 space-y-2">
                                            {[...profile].map((item) => (
                                                <DisclosureButton
                                                    key={item.name}
                                                    as="a"
                                                    href={item.href}
                                                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                >
                                                    {item.name}
                                                </DisclosureButton>
                                            ))}
                                        </DisclosurePanel>
                                    </Disclosure>) : ''}
                            </div>
                            <div className="py-6">
                                {isAuthenticated ? (
                                    <button onClick={onLogout} className='flex  bg-red-500 w-full items-center justify-center text-white rounded-xl py-4 text-lg font-semibold'>Logout <IoLogOutOutline className='text-xl' /></button>
                                ) : (
                                    <a
                                        href="/login"
                                        className="-mx-3 w-40 flex items-center inset-y-0 left-0 transition-all duration-700 ease-in-out hover:w-full hover:bg-green-500 rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 "
                                    >
                                        {t('nav.Login')}<RiLoginBoxLine className='text-xl' />
                                    </a>
                                )}
                            </div>
                            <div className="flex py-6 left-0 justify-between items-center gap-x-4">
                                <a href="/cart" className="flex font-semibold leading-6 text-orange-500 p-4 rounded-full">
                                    <FaCartPlus className='text-5xl' />
                                    {totalProducts ?
                                        <div className=' bg-red-500 flex aspect-square w-5 h-5 rounded-full items-center justify-center text-white animate-pulse'>{totalProducts}</div>
                                        : ''}
                                </a>
                                <DarkModeToggle />
                            </div>
                            <LanguageSwitcher />
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    )
}