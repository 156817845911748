// ScrollToTop.js
import React, { useState, useEffect } from "react";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    const intervalId = setInterval(() => {
      if (window.scrollY > 0) {
        window.scrollBy(0, -50); // Di chuyển lên 50px mỗi 10ms
      } else {
        clearInterval(intervalId); // Dừng cuộn khi đến đầu trang
      }
    }, 10); // Thời gian giữa các lần cuộn (10ms)
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="fixed bottom-4 right-5">
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="bg-[#ffa526] text-3xl font-bold text-white p-2 px-4 rounded-full shadow-lg shadow-gray-500 hover:bg-orange-700 transition duration-300"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
