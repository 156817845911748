import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import ApiContext from "../../api/ApiContext";

const Orders = () => {
    const token = localStorage.getItem('token');
    const [orders, setOrders] = useState([]);
    const [thanhtoan, setThanhtoan] = useState(false);
    const [iddonhang, setID] = useState();
    const [idKH, setKH] = useState([]);
    const [name, setNameKH] = useState([]);
    const { khachhang } = useContext(ApiContext);
    const [role, setRole] = useState("");
    const { accountData } = useContext(ApiContext);
    useEffect(() => {
        if (accountData) {
            setRole(accountData.Role);
        }
    }, [accountData]);
    useEffect(() => {
        if (khachhang) {
            setKH(khachhang.IdKH);
            setNameKH(khachhang.TenKH);
        }
    }, [khachhang]);
    useEffect(() => {
        // Lấy dữ liệu đơn hàng từ API
        axios.get(`${process.env.REACT_APP_HOST}/api/orders`)
            .then((response) => {
                setOrders(response.data);
            })
            .catch((error) => {
                console.error('Đã xảy ra lỗi khi lấy đơn hàng:', error);
            });
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const order = orders.filter((or) => or.KhachHangID === idKH)
    const paginatedOrders = order.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const totalPages = Math.ceil(order.length / pageSize);

    const handleCancelOrder = (orderId, userRole = role) => {  // Cung cấp giá trị mặc định cho userRole
        const orderToCancel = orders.find((order) => order.DonHangID === orderId);
        console.log('orderToCancel:', orderToCancel);  // Kiểm tra đối tượng đơn hàng
        console.log('userRole:', userRole);  // Kiểm tra vai trò người dùng

        if (orderToCancel) {
            console.log('order status:', orderToCancel.DonHangTrangThai);  // Kiểm tra trạng thái đơn hàng
        }

        // Kiểm tra điều kiện
        if ((userRole === 'user' && orderToCancel &&
            (orderToCancel.DonHangTrangThai === 'pending' || orderToCancel.DonHangTrangThai === 'processing')) ||
            userRole === 'admin') {

            fetch(`${process.env.REACT_APP_HOST}/api/orders/cancel/${orderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // Gửi token vào header
                },
            })
                .then(response => response.json())
                .then(data => {
                    alert(data.message);
                    setOrders((prevOrders) =>
                        prevOrders.map(order =>
                            order.DonHangID === orderId
                                ? { ...order, DonHangTrangThai: 'cancelled' }
                                : order
                        )
                    );
                })
                .catch((error) => {
                    console.error('Đã xảy ra lỗi khi hủy đơn hàng:', error);
                });
        } else {
            alert('Không thể hủy đơn hàng trong trạng thái này hoặc bạn không có quyền hủy');
        }
    };
    const handlePaymentVNPay = async (orderId) => {
        if (!orderId || !name) return null;  // Nếu không có orderId hoặc tên khách hàng thì không thực hiện
        console.log(orderId);
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST_PAY}/create_payment_url`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ DonHangID: orderId, TenKH: name })
            });
            const data = await response.json();
            if (response.ok) {
                return window.location.href = data.paymentUrl;
            } else {
                console.error('Lỗi VNPay:', data.error);
                return null;  // Nếu có lỗi, trả về null
            }
        } catch (error) {
            console.error('Lỗi VNPay:', error);
            return null;  // Nếu có lỗi mạng, trả về null
        }
    };

    const handlePaymentMoMo = async (orderId) => {
        if (!orderId || !name) return null;  // Nếu không có orderId hoặc tên khách hàng thì không thực hiện
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST_PAY}/payment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ DonHangID: orderId, TenKH: name })
            });
            const data = await response.json();
            if (response.ok) {
                return window.location.href = data.payUrl;
            } else {
                console.error('Lỗi MoMo:', data.error);
                return null;  // Nếu có lỗi, trả về null
            }
        } catch (error) {
            console.error('Lỗi MoMo:', error);
            return null;  // Nếu có lỗi mạng, trả về null
        }
    };

    const handlePayment = (orderId) => {
        setThanhtoan(true);
        setID(orderId);
    };
    const handleDeleteOrder = (orderId) => {
        // Tìm trạng thái đơn hàng
        const orderToDelete = orders.find((order) => order.DonHangID === orderId);

        if (orderToDelete && (orderToDelete.DonHangTrangThai === 'completed' || orderToDelete.DonHangTrangThai === 'cancelled')) {
            axios.delete(`${process.env.REACT_APP_HOST}/api/orders/delete/${orderId}`)
                .then((response) => {
                    alert(response.data.message);
                    setOrders((prevOrders) =>
                        prevOrders.filter(order => order.DonHangID !== orderId)
                    );
                })
                .catch((error) => {
                    console.error('Đã xảy ra lỗi khi xóa đơn hàng:', error);
                });
        } else {
            alert('Không thể xóa đơn hàng trong trạng thái này');
        }
    };


    return (
        <div className="container relative mx-auto p-4">
            <h2 className="text-2xl font-semibold mb-4">Danh sách đơn hàng</h2>
            <div className="overflow-x-auto h-1/2">
                <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg overflow-hidden">
                    <thead>
                        <tr className="bg-gray-100 text-left">
                            <th className="py-2 px-4 border-b">MDH</th>
                            <th className="py-2 px-4 border-b">Ngày Đặt</th>
                            <th className="py-2 px-4 border-b">Email</th>
                            <th className="py-2 px-4 border-b">Tên Tour</th>
                            <th className="py-2 px-4 border-b">Số Lượng</th>
                            <th className="py-2 px-4 border-b">Giá Vé</th>
                            <th className="py-2 px-4 border-b">Trạng Thái</th>
                            <th className="py-2 px-4 border-b">Hành Động</th>
                            <th className="py-2 px-4 border-b">Thanh Toán</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedOrders.map((order, index) => {
                            let statusClass = "";
                            switch (order.DonHangTrangThai) {
                                case "pending":
                                    statusClass = "bg-yellow-300 text-yellow-800"; // Màu vàng cho trạng thái "Đã đặt hàng"
                                    break;
                                case "processing":
                                    statusClass = "bg-blue-300 text-blue-800"; // Màu xanh dương cho trạng thái "Đang xử lý"
                                    break;
                                case "paid":
                                    statusClass = "bg-green-300 text-green-800"; // Màu xanh lá cho trạng thái "Đã thanh toán"
                                    break;
                                case "completed":
                                    statusClass = "bg-teal-300 text-teal-800"; // Màu xanh ngọc cho trạng thái "Hoàn tất"
                                    break;
                                case "cancelled":
                                    statusClass = "bg-red-300 text-red-800"; // Màu đỏ cho trạng thái "Hủy bỏ"
                                    break;
                                default:
                                    statusClass = "bg-gray-300 text-gray-800"; // Màu xám cho trạng thái mặc định
                            }

                            return (
                                <tr key={order.DonHangID} className="hover:bg-gray-50">
                                    <td className=" p-1 px-4 border-b text-sm">{order.DonHangID}</td>
                                    <td className=" p-1 px-4 border-b text-sm">{new Date(order.NgayDatHang).toLocaleDateString('vi-VN')}</td>
                                    <td className=" p-1 px-4 border-b text-sm">{order.Email}</td>
                                    <td className=" p-1 px-4 border-b text-sm">{order.TourName}</td>
                                    <td className="text-center p-1 border-b text-sm">{order.SoLuong}</td>
                                    <td className="text-center p-1 border-b text-sm">{parseFloat(order.GiaVe).toLocaleString('vi-VN')} VND</td>
                                    <td className="text-center p-1 border-b text-sm">
                                        <button className={`px-2 rounded-xl ${statusClass}`}>{order.DonHangTrangThai}</button>
                                    </td>
                                    <td className="p-1 px-4 border-b">
                                        {order.DonHangTrangThai !== 'cancelled' && order.DonHangTrangThai !== 'paid' && (
                                            <button
                                                onClick={() => handleCancelOrder(order.DonHangID)}
                                                className="bg-red-500 text-white text-sm mx-2 px-2 py-1 rounded-md">
                                                Hủy Đơn
                                            </button>
                                        )}
                                    </td>
                                    <td className="p-1 px-4 border-b ">
                                        {order.DonHangTrangThai !== 'cancelled'
                                            && order.DonHangTrangThai !== 'paid'
                                            && order.DonHangTrangThai !== 'completed'
                                            && (
                                                <button
                                                    onClick={() => handlePayment(order.DonHangID)}
                                                    className="bg-green-500 text-white text-sm mx-2 px-2 py-1 rounded-md">
                                                    Thanh Toán
                                                </button>
                                            )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                >
                    {'<'}
                </button>
                <span className="px-4 py-2">{currentPage} / {totalPages}</span>

                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                >
                    {'>'}
                </button>
            </div>

            {thanhtoan && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='bg-white p-4 rounded-lg'>
                        <div className='flex items-end justify-end gap-x-5'>
                            <div className='text-lg font-bold text-start'>Chọn ứng dụng để thanh toán</div>
                            <button onClick={(e) => setThanhtoan(false)} className=' bg-red-500 px-2 text-white rounded-md'>x</button>
                        </div>
                        <div className='grid grid-flow-col gap-4 p-8'>
                            <button
                                onClick={() => handlePaymentVNPay(iddonhang)}
                            >
                                <img className="w-20 h-20 aspect-square border-2 border-blue-300 rounded-xl " src='https://vnpay.vn/s1/statics.vnpay.vn/2023/9/06ncktiwd6dc1694418196384.png' alt='VNPay' />
                            </button>
                            <button
                                onClick={() => handlePaymentMoMo(iddonhang)}
                            >
                                <img className="w-20 h-20 aspect-square border-2 border-pink-300 rounded-xl" src='https://developers.momo.vn/v3/vi/assets/images/icon-52bd5808cecdb1970e1aeec3c31a3ee1.png' alt='MoMo' />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};



export default Orders;
