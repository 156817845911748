import React, { useState } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';

function NewPassword() {
    const [email, setEmail] = useState('');
    const [accountName, setAccountName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleRequestNewPassword = async () => {
        if (!email || !accountName) {
            alert('Vui lòng nhập Email và Tên tài khoản.');
            return;
        }

        setLoading(true);

        try {
            // Gửi yêu cầu đến backend để tạo mật khẩu mới
            const response = await axios.post(
                `${process.env.REACT_APP_HOST}/api/account/request-new-password`,
                { email, accountName }
            );

            const { message, newPassword } = response.data;
            alert(message);

            // Gửi mật khẩu mới qua EmailJS
            const emailParams = {
                to_email: email,
                message: `Tài khoản có tên ${accountName} đã được đổi mật khẩu thành công. Mật khẩu mới ${newPassword}.`,
            };

            await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE,
                process.env.REACT_APP_EMAILJS_TEMPLATE_SendAdmin,
                emailParams,
                process.env.REACT_APP_EMAILJS_kEY
            );

            alert('Mật khẩu mới đã được gửi đến email của bạn.');
        } catch (error) {
            console.error('Error:', error);
            alert('Có lỗi xảy ra. Vui lòng thử lại.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-24 w-full mx-auto bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Yêu cầu mật khẩu mới</h2>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                </label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tên tài khoản
                </label>
                <input
                    type="text"
                    value={accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <button
                onClick={handleRequestNewPassword}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-full"
                disabled={loading}
            >
                {loading ? 'Đang xử lý...' : 'Gửi yêu cầu'}
            </button>
        </div>
    );
}

export default NewPassword;
