import React, { useEffect } from 'react';
import ReactDOM from "react-dom/client";
import "./index.css";
import './css/tailwindcss.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './i18n'; // Nhúng cấu hình i18n vào ứng dụng

const App = () => {
  useEffect(() => {
    // Khởi tạo AOS khi ứng dụng được tải
    AOS.init({
      offset: 200,
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });

    // Cleanup AOS khi component bị unmount
    return () => AOS.refresh();
  }, []);  // Mảng phụ thuộc rỗng để đảm bảo useEffect chỉ chạy một lần

  return (
    <Router />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Đo lường hiệu suất của ứng dụng
reportWebVitals();
