import React, { useState, useContext } from 'react';
import StarRating from './StarRating';
import { TranslationContext } from '../locales/TranslationContext';
import { Link } from 'react-router-dom';
import ApiContext from '../../api/ApiContext';

function Category() {
    var productRating = 3;
    const { products } = useContext(ApiContext); // Use data from Context

    // State for managing the index of products to show
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const filteredHistory = products.filter((product) => product.TouTL === 1);
    const filteredNative = products.filter((product) => product.TouTL === 2);
    const filteredSea = products.filter((product) => product.TouTL === 3);

    // Phân trang sau khi lọc
    const fillItemHitory = filteredHistory.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const fillItemNative = filteredNative.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const fillItemSea = filteredSea.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const handleNextPage = () => {
        if ((currentPage + 1) * itemsPerPage < Math.max(filteredHistory.length, filteredNative.length, filteredSea.length)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='dark:bg-gray-700'>
            <div className='p-4 md:p-16 w-full overflow-hidden'>
                <div className='font-bold text-lg md:text-2xl py-4 dark:text-white'>Thể loại du lịch</div>
                <div className='h-[2px] bg-gray-400'></div>

                <div className='grid grid-flow-row gap-3'>
                    {/* Header with Category and Navigation Buttons */}
                    <div className='flex justify-between pt-2'>
                        <button className='bg-blue-300 rounded-3xl px-6 py-2 text-white font-semibold'>Du lịch biển</button>
                        <div className='flex space-x-2'>
                            <button
                                onClick={handlePrevPage}
                                className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                            >
                                {'<'}
                            </button>
                            <button
                                onClick={handleNextPage}
                                className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                            >
                                {'>'}
                            </button>
                        </div>
                    </div>

                    {/* Grid for Travel Items */}
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                        {fillItemSea.map((product) => (
                            <Link key={product.TourId} to={`/propertiesdetail/${product.TourId}`}>
                                <div className='h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group'>
                                    <div className='h-full'>
                                        <div className='rounded-t-xl overflow-hidden'>
                                            <img
                                                className='rounded-t-xl aspect-square object-cover transform transition-transform duration-500 group-hover:scale-110'
                                                src={product.TourImage}
                                                alt='Alaska, Westminster to Greenwich River Thames'
                                            />
                                        </div>
                                        <div className='p-4 flex flex-col justify-between'>
                                            <div className='font-bold text-base'>
                                                {product.TenQuocGia}, {product.TourName}
                                            </div>
                                            <div className='h-full'>
                                                <div className='text-sm font-semibold text-gray-500'>Số ngày đi: {product.TourDay}</div>
                                                <div className='text-sm flex font-semibold text-gray-500'>
                                                    Ngày xuất phát: <span className='px-1'>{product.TourTime ? <div>{new Date(product.TourTime).toLocaleDateString('vi-VN')}</div> : 'Unlimited'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-2 py-4'>
                                        <div className='h-[1px] bg-gray-300 my-2'></div>
                                        <div className='flex justify-between items-center max-sm:flex-col lg:flex-col xl:flex-row xl:justify-between'>
                                            <StarRating className='w-1/2' rating={productRating} />
                                            <div className='text-orange-400 font-bold '> {parseFloat(product.TourPrice).toLocaleString('vi-VN')} VND</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className='grid grid-flow-row gap-3'>
                    {/* Header with Category and Navigation Buttons */}
                    <div className='flex justify-between pt-2'>
                        <button className='bg-orange-300 rounded-3xl px-6 py-2 text-white font-semibold'>Du lịch sử</button>
                        <div className='flex space-x-2'>
                            <button
                                onClick={handlePrevPage}
                                className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                            >
                                {'<'}
                            </button>
                            <button
                                onClick={handleNextPage}
                                className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                            >
                                {'>'}
                            </button>
                        </div>
                    </div>

                    {/* Grid for Travel Items */}
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                        {fillItemHitory.map((product) => (
                            <Link key={product.TourId} to={`/propertiesdetail/${product.TourId}`}>
                                <div className='h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group'>
                                    <div className='h-full'>
                                        <div className='rounded-t-xl overflow-hidden'>
                                            <img
                                                className='rounded-t-xl aspect-square object-cover transform transition-transform duration-500 group-hover:scale-110'
                                                src={product.TourImage}
                                                alt='Alaska, Westminster to Greenwich River Thames'
                                            />
                                        </div>
                                        <div className='p-4 flex flex-col justify-between'>
                                            <div className='font-bold text-base'>
                                                {product.TenQuocGia}, {product.TourName}
                                            </div>
                                            <div className='h-full'>
                                                <div className='text-sm font-semibold text-gray-500'>Số ngày đi: {product.TourDay}</div>
                                                <div className='text-sm flex font-semibold text-gray-500'>
                                                    Ngày xuất phát: <span className='px-1'>{product.TourTime ? product.TourTime : 'Unlimited'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-2 py-4'>
                                        <div className='h-[1px] bg-gray-300 my-2'></div>
                                        <div className='flex justify-between items-center max-sm:flex-col lg:flex-col xl:flex-row xl:justify-between'>
                                            <StarRating rating={productRating} />
                                            <div className='text-orange-400 font-bold '> {parseFloat(product.TourPrice).toLocaleString('vi-VN')} VND</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className='grid grid-flow-row gap-3'>
                    {/* Header with Category and Navigation Buttons */}
                    <div className='flex justify-between pt-2'>
                        <button className='bg-green-300 rounded-3xl px-6 py-2 text-white font-semibold'>Du lịch thiên nhiên</button>
                        <div className='flex space-x-2'>
                            <button
                                onClick={handlePrevPage}
                                className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                            >
                                {'<'}
                            </button>
                            <button
                                onClick={handleNextPage}
                                className='w-10 h-10 rounded-full border-2 border-orange-300 shadow-lg hover:bg-orange-300 hover:border-white hover:border-4'
                            >
                                {'>'}
                            </button>
                        </div>
                    </div>

                    {/* Grid for Travel Items */}
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                        {fillItemNative.map((product) => (
                            <Link key={product.TourId} to={`/propertiesdetail/${product.TourId}`}>
                                <div className='h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group'>
                                    <div className='h-full'>
                                        <div className='rounded-t-xl overflow-hidden'>
                                            <img
                                                className='rounded-t-xl aspect-square object-cover transform transition-transform duration-500 group-hover:scale-110'
                                                src={product.TourImage}
                                                alt='Alaska, Westminster to Greenwich River Thames'
                                            />
                                        </div>
                                        <div className='p-4 flex flex-col justify-between'>
                                            <div className='font-bold text-base'>
                                                {product.TenQuocGia}, {product.TourName}
                                            </div>
                                            <div className='h-full'>
                                                <div className='text-sm font-semibold text-gray-500'>Số ngày đi: {product.TourDay}</div>
                                                <div className='text-sm flex font-semibold text-gray-500'>
                                                    Ngày xuất phát: <span className='px-1'>{product.TourTime ? product.TourTime : 'Unlimited'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-2 py-4'>
                                        <div className='h-[1px] bg-gray-300 my-2'></div>
                                        <div className='flex justify-between items-center max-sm:flex-col lg:flex-col xl:flex-row xl:justify-between'>
                                            <StarRating rating={productRating} />
                                            <div className='text-orange-400 font-bold  '> {parseFloat(product.TourPrice).toLocaleString('vi-VN')} VND</div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category;
