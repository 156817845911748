import '../../css/edit.css';
import user from '../../img/hero/traveluser.png';
import { FaPlay } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Home1() {
    const { t } = useTranslation();

    return (
        <div className='lg:w-screen lg:h-screen bg-img dark:bg-gray-700'>
            <div className='flex max-sm:px-8 max-sm:py-20 sm:px-10 sm:py-28 md:px-12 md:py-32 lg:px-16 lg:py-36'>
                <div className='w-1/2'>
                    <h1 className='text-orange-500 font-bold max-sm:text-sm sm:text-md lg:text-lg xl:text-xl'>
                        {t('home.home1')}
                    </h1>
                    <div className='max-sm:text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-7xl pt-5 max-md:pt-2 font-medium tracking-tighter'>
                        {t('home.home2')}
                    </div>
                    <div className='pt-5 max-sm:text-md sm:text-md sm:pt-2 md:text-lg lg:text-xl xl:text-2xl'>
                        {t('home.home3')}
                    </div>
                    <div>
                        <div className='flex items-center justify-start mt-5'>
                            <button className='px-4 py-3 text-center rounded-lg text-white bg-[#F1A501] max-sm:text-[8px] sm:h-10 sm:text-[10px] sm:px-1'>
                                {t('home.home4')}
                            </button>
                            <div className='ml-5 scale-[0.8]'>
                                <button className='p-3 rounded-full bg-[#DF6951]'>
                                    <FaPlay className='text-white' />
                                </button>
                            </div>
                            <div className='pl-2 font-semibold text-gray-500 max-md:text-[10px]'>
                                {t('home.home5')}
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    src={user}
                    className=" aspect-square w-1/2 hidden lg:flex  "
                    data-aos='slide-left'
                />
            </div>
        </div>
    );
}

export default Home1;
