import React, { useState, useContext } from 'react';
import StarRating from '../properties/StarRating';
import ApiContext from '../../api/ApiContext';
import { useTranslation } from "react-i18next";
import Loading from '../Loading';

function Home3() {
    var productRating = 3;
    const { products } = useContext(ApiContext);
    const { t } = useTranslation();

    if (products.length <= 0) return <Loading />;
    return (
        <div className=' px-16 flex flex-col overflow-hidden dark:bg-gray-700'>
            <div className='flex flex-col items-center justify-center'>
                <h2 className='font-semibold text-gray-500 text-lg'>{t('home.home10')}</h2>
                <h1 className='text-4xl font-bold pt-3 max-sm:text-2xl sm:text-3xl'>{t('home.home11')}</h1>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-10 py-4'>
                {products && products.slice(0, 4).map((product) => (
                    <a key={product.TourId} href={`/propertiesdetail/${product.TourId}`}>
                        <div data-aos="zoom-in" className='h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group'>
                            <div className='h-full'>
                                <div className='rounded-t-xl overflow-hidden'>
                                    <img
                                        className='rounded-t-xl aspect-square object-cover transform transition-transform duration-500 group-hover:scale-110'
                                        src={product.TourImage}
                                        alt='Alaska, Westminster to Greenwich River Thames'
                                    />
                                </div>
                                <div className='p-4 flex flex-col justify-between'>
                                    <div className='font-bold text-base'>
                                        {product.TenQuocGia}, {product.TourName}
                                    </div>
                                    <div className='h-full'>
                                        <div className='text-sm font-semibold text-gray-500'>Số ngày đi: {product.TourDay}</div>
                                        <div className='text-sm flex font-semibold text-gray-500'>
                                            Ngày bắt đầu: <span className='px-1'>{product.TourTime ? product.TourTime : 'Unlimited'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='p-2 py-4'>
                                <div className='h-[1px] bg-gray-300 my-2'></div>
                                <div className='flex justify-between items-center max-sm:flex-col lg:flex-col xl:flex-row xl:justify-between'>
                                    <StarRating rating={productRating} />
                                    <div className='text-orange-400 font-bold'> {parseFloat(product.TourPrice).toLocaleString('vi-VN')} VND</div>
                                </div>
                            </div>
                        </div>
                    </a>
                ))}


            </div>
        </div>

    );
}
export default Home3;

