import React, { useState, useEffect } from 'react';
import CheckoutForm from './CheckoutForm'; // Import CheckoutForm

const CartDetails = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [khachhang, setKH] = useState([]);
    const token = localStorage.getItem('token');
    const [idKH, setIdKH] = useState(null);
    const [name, setName] = useState(null);
    const [diachi, setDC] = useState(null);
    const [sdt, setSDT] = useState(null);
    const [userID, setUser] = useState(null);
    const [showCheckout, setShowCheckout] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_HOST}/api/khachhang/login`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Gửi token trong header
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setKH(data);
            })
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    useEffect(() => {
        setIdKH(khachhang.IdKH);
        setName(khachhang.TenKH);
        setDC(khachhang.DiaChi);
        setSDT(khachhang.SDT);
    }, [khachhang]);

    useEffect(() => {
        if (!idKH) {
            setError('Chưa có thông tin khách hàng.');
            setLoading(false);
            return;
        }

        const fetchCartData = async () => {
            setLoading(true); // Hiển thị trạng thái đang tải
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}/api/giohang/${idKH}`);
                if (!response.ok) {
                    throw new Error('Lỗi khi tải giỏ hàng.');
                }
                const data = await response.json();
                setCartItems(data);
                setError(null); // Xóa lỗi trước đó nếu thành công
            } catch (err) {
                setError('Chưa có sản phẩm.');
            } finally {
                setLoading(false); // Tắt trạng thái tải
            }
        };

        fetchCartData();
    }, [idKH]);


    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.GiaVe * item.SoLuongVe, 0);
    };
    const total = calculateTotal();

    const increaseQuantity = async (chiTietGioHangId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/giohang/increase/${chiTietGioHangId}`, {
                method: 'PUT',
            });
            if (response.ok) {
                setCartItems((prevItems) =>
                    prevItems.map((item) =>
                        item.ChiTietGioHangID === chiTietGioHangId
                            ? { ...item, SoLuongVe: item.SoLuongVe + 1 }
                            : item
                    )
                );
            } else {
                console.error('Lỗi khi tăng số lượng vé');
            }
        } catch (error) {
            console.error('Lỗi khi tăng số lượng vé:', error);
        }
    };

    const decreaseQuantity = async (chiTietGioHangId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/giohang/decrease/${chiTietGioHangId}`, {
                method: 'PUT',
            });
            if (response.ok) {
                setCartItems((prevItems) =>
                    prevItems.map((item) =>
                        item.ChiTietGioHangID === chiTietGioHangId && item.SoLuongVe > 1
                            ? { ...item, SoLuongVe: item.SoLuongVe - 1 }
                            : item
                    )
                );
            } else {
                console.error('Lỗi khi giảm số lượng vé');
            }
        } catch (error) {
            console.error('Lỗi khi giảm số lượng vé:', error);
        }
    };

    const cancelItem = async (chiTietGioHangId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/api/giohang/delete/${chiTietGioHangId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setCartItems((prevItems) => prevItems.filter((item) => item.ChiTietGioHangID !== chiTietGioHangId));
            } else {
                console.error('Lỗi khi xóa vé');
            }
        } catch (error) {
            console.error('Lỗi khi xóa vé:', error);
        }
    };
    const totalQuantity = cartItems.length;

    if (loading) return <p>Đăng Nhập Để Mua Hàng</p>;
    if (error) return <p>{error}</p>;
    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Giỏ hàng</h2>
            <table className="min-w-full table-auto border-collapse">
                <thead>
                    <tr>
                        <th className="px-4 py-2 border-b">Tên Tour</th>
                        <th className="px-4 py-2 border-b">Giá Vé</th>
                        <th className="px-4 py-2 border-b">Số Lượng</th>
                        <th className="px-4 py-2 border-b">Tổng</th>
                        <th className="px-4 py-2 border-b">Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems.map((item) => (
                        <tr key={item.ChiTietGioHangID}>
                            <td className="px-4 py-2 border-b">{item.TourName}</td>
                            <td className="px-4 py-2 border-b">{parseFloat(item.GiaVe).toLocaleString('vi-VN')}</td>
                            <td className="px-4 py-2 border-b flex items-center justify-center">
                                <button
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    onClick={() => decreaseQuantity(item.ChiTietGioHangID)}
                                >
                                    -
                                </button>
                                <span className="mx-4">{item.SoLuongVe}</span>
                                <button
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    onClick={() => increaseQuantity(item.ChiTietGioHangID)}
                                >
                                    +
                                </button>
                            </td>
                            <td className="px-4 py-2 border-b">{parseFloat(item.GiaVe * item.SoLuongVe).toLocaleString('vi-VN')} VND</td>
                            <td className="px-4 py-2 border-b">
                                <button
                                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                    onClick={() => cancelItem(item.ChiTietGioHangID)}
                                >
                                    Hủy
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3 className="text-xl font-semibold mt-4">Tổng: {total} $</h3>
            <button
                className={showCheckout ? `mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600` : `mt-4 px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600`}
                onClick={() => setShowCheckout(!showCheckout)}
            >
                {showCheckout ? 'X' : `Thanh Toán (${totalQuantity})`}
            </button>
            {showCheckout && <CheckoutForm cartItems={cartItems} name={name} idKH={idKH} diachi={diachi} sdt={sdt} />}
        </div>

    );
};

export default CartDetails;
