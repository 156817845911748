import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="bg-[#ffebd3] text-black py-12">
            <div className="container mx-auto px-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
                {/* Logo and Description */}
                <div className="space-y-4">
                    <h1 className="text-3xl font-bold bg-gradient-to-r from-orange-500 to-red-600 text-transparent bg-clip-text">{t('footer.footer1')}</h1>
                    <p>
                        {t('footer.footer2')}
                    </p>
                    <div className="flex space-x-4">
                        <a href="#" className="text-gray-400 hover:text-white">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" className="text-gray-400 hover:text-white">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="#" className="text-gray-400 hover:text-white">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>

                {/* Useful Links */}
                <div className="space-y-4">
                    <h3 className="font-semibold text-lg">{t('footer.footer3')}</h3>
                    <ul className="space-y-2">
                        <li><a href="#" className="hover:text-orange-500">{t('footer.footer4')}</a></li>
                        <li><a href="#" className="hover:text-orange-500">{t('footer.footer5')}</a></li>
                        <li><a href="#" className="hover:text-orange-500">{t('footer.footer6')}</a></li>
                        <li><a href="#" className="hover:text-orange-500">{t('footer.footer7')}</a></li>
                    </ul>
                </div>

                {/* Contact Information */}
                <div className="space-y-4">
                    <h3 className="font-semibold text-lg">{t('footer.footer8')}</h3>
                    <ul className="space-y-2">
                        <li><span className="font-bold">{t('footer.footer9')}:</span> {t('footer.footer10')}</li>
                        <li><span className="font-bold">{t('footer.footer12')}:</span> {t('footer.footer13')}</li>
                        <li><span className="font-bold">{t('footer.footer14')}:</span> {t('footer.footer15')}</li>
                    </ul>
                </div>

                {/* Newsletter Signup */}
                <div className="space-y-4">
                    <h3 className="font-semibold text-lg">{t('footer.footer16')}</h3>
                    <p>{t('footer.footer17')}</p>
                    <form className="flex">
                        <input
                            type="email"
                            placeholder={t('footer.footer18')}
                            className="px-4 py-2 rounded-l-lg border border-gray-300 w-full focus:outline-none focus:ring-2 focus:ring-orange-500"
                        />
                        <a
                            href='/contact'
                            type="submit"
                            className="bg-orange-500 text-white px-6 py-2 rounded-r-lg hover:bg-orange-600 transition duration-300"
                        >
                            {t('footer.footer19')}
                        </a>
                    </form>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className="bg-orange-500 text-black py-4 mt-8">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 Travel AuOrient. Tất cả quyền được bảo lưu.</p>
                </div>
            </div>
        </footer>
    );
}
export default Footer;
