import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../css/edit.css';
import contact from '../img/contact/contact.jpg';

function Contact() {
  const [formData, setFormData] = useState({
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Kiểm tra dữ liệu trước khi gửi
    if (!formData.email || !formData.message) {
      alert('Email và Message là bắt buộc!');
      return;
    }

    // Sử dụng EmailJS để gửi email
    emailjs.sendForm(`${process.env.REACT_APP_EMAILJS_SERVICE}`, `${process.env.REACT_APP_EMAILJS_TEMPLATE_SendUser}`, e.target, `${process.env.REACT_APP_EMAILJS_kEY}`)
      .then(
        (result) => {
          console.log(result.text);
          alert('Email đã được gửi thành công!');
          setFormData({ lastName: '', firstName: '', email: '', phone: '', message: '' });
        },
        (error) => {
          console.log(error.text);
          alert('Không thể gửi email. Vui lòng thử lại!');
        }
      );
  };

  return (
    <div className="bg-contact w-screen h-screen relative">
      {/* Backdrop Blur Overlay */}
      <div className="absolute inset-0 backdrop-blur-md"></div>

      {/* Content Section */}
      <div className="pt-12 relative z-10 w-full h-full flex flex-col items-center justify-center">
        {/* Header Section */}
        <div className='m-4'>
          <div className='text-center text-5xl font-bold bg-gradient-to-r from-white to-orange-500 text-transparent bg-clip-text'>
            Get in touch
          </div>
          <div className='text-center text-white text-xs'>
            Reach out, and let's create a universe of possibilities together!
          </div>
        </div>

        {/* Form and Image Section */}
        <div className='w-full sm:w-[70%] lg:w-[60%] h-[70%] sm:h-[80%] grid grid-cols-1 sm:grid-cols-2 rounded-xl bg-[#0a0d17ce]'>
          {/* Form Section */}
          <form className='px-10 py-6' onSubmit={handleSubmit}>
            <div className='text-white text-lg font-semibold'>
              Let’s connect constellations
            </div>
            <div className='text-white text-[10px]'>
              Let's align our constellations! Reach out and let the magic of collaboration illuminate our skies.
            </div>

            {/* Form Inputs */}
            <div className='py-2 w-full grid grid-flow-row gap-2'>
              <div className='grid grid-cols-2 gap-x-2'>
                <div data-aos='fade-up' data-aos-delay="100">
                  <input
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="rounded-sm text-white p-2 bg-[#ffffff18] w-full"
                    placeholder="Last Name"
                  />
                </div>
                <div data-aos='fade-up' data-aos-delay="200">
                  <input
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="rounded-sm text-white p-2 bg-[#ffffff18] w-full"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div data-aos='fade-up' data-aos-delay="300">
                <input
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="rounded-sm text-white p-2 bg-[#ffffff18] w-full"
                  placeholder="Email"
                />
              </div>
              <div data-aos='fade-up' data-aos-delay="400">
                <input
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="rounded-sm text-white p-2 bg-[#ffffff18] w-full"
                  placeholder="Phone Number"
                />
              </div>
              <div data-aos='fade-up' data-aos-delay="500">
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="rounded-sm text-white p-2 bg-[#ffffff18] w-full h-[120%] resize-none"
                  placeholder="Message"
                />
              </div>
            </div>

            {/* Submit Button */}
            <button data-aos='fade-up' data-aos-delay="600" className='bg-orange-300 hover:bg-orange-500 hover:text-white w-full p-2 my-3 font-semibold rounded-sm'>
              Submit
            </button>
          </form>

          {/* Image Section */}
          <div className='w-full h-full p-4 overflow-hidden'>
            <img className='h-full w-full object-cover rounded-xl' src={contact} alt="Contact Us" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

