import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

function Google({ onLogin }) {
    const [profile, setProfile] = useState(null);
    const [message, setMessage] = useState(""); // To store messages from API
    const navigate = useNavigate();  // Sử dụng useNavigate


    // Đăng nhập bằng Google
    const handleGoogleLoginSuccess = async (response) => {
        const token = response.credential;
        try {
            const res = await fetch(`${process.env.REACT_APP_HOST}/api/google-auth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token }),
            });
            const data = await res.json();
            localStorage.setItem("token", (data.token));
            if (res.ok) {
                if (data.user) {
                    // Cập nhật state userID bằng data.user.ID
                    const userId = data.user.ID;
                    console.log(data.user.ID);
                    sessionStorage.setItem('userId', userId);
                    // Cập nhật trạng thái thành 'on' sau khi đăng nhập thành công
                    await fetch(`${process.env.REACT_APP_HOST}/api/account/${data.user.ID}/trangthai`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ TrangThai: "on" }),
                    });

                    onLogin(); // Call onLogin prop
                    navigate("/profile/account-general"); // Redirect to profile page
                    setMessage("Đăng nhập thành công");
                } else {
                    console.error('User data is missing in response:', data);
                }
            } else {
                setMessage(data.error || "Có lỗi xảy ra");
            }
        } catch (err) {
            console.error("Google Auth Error:", err);
            setMessage("Đăng nhập bằng Google thất bại.");
        }
    };

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                {!profile ? (
                    <div className="p-6 bg-white shadow-lg rounded-lg flex flex-col items-center space-y-4">
                        <h1 className="text-2xl font-semibold text-gray-700">Đăng nhập với Google</h1>
                        <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onError={() => setMessage("Lỗi khi đăng nhập với Google!")} />
                    </div>
                ) : (
                    <div className="p-6 bg-white shadow-lg rounded-lg flex flex-col items-center space-y-4">
                        <h2 className="text-2xl font-semibold text-gray-700">
                            Chào mừng, {profile.name}
                        </h2>
                        <p className="text-gray-600">Email: {profile.email}</p>
                        <img
                            src={profile.picture}
                            alt="Profile"
                            className="w-24 h-24 rounded-full shadow-md"
                        />
                    </div>
                )}
            </div>
        </GoogleOAuthProvider>
    );
}

export default Google;
