import React, { useState, useContext, useEffect } from "react";
import ApiContext from '../../api/ApiContext';
import axios from "axios";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { CiLinkedin } from "react-icons/ci";
import { FaInstagramSquare } from "react-icons/fa";

function AccountSocialLinks() {
    const [socialLinks, setSocialLinks] = useState({
        Twitter: '',
        Facebook: '',
        LinkedIn: '',
        Instagram: '',
    });
    const { khachhang } = useContext(ApiContext); // Use data from Context
    const handleChange = (e) => {
        setSocialLinks({ ...socialLinks, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        console.log('Dữ liệu gửi đi:', socialLinks); // Không cần khachhangId nữa
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_HOST}/api/khachhang-social`,
                socialLinks, // Chỉ gửi các thông tin mạng xã hội
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}` // Lấy token từ localStorage
                    }
                }
            );
            alert(response.data.message);
            return window.location.href = '/profile/account-social-links';
        } catch (error) {
            console.error('Lỗi cập nhật:', error);
            alert('Cập nhật thất bại');
        }
    };

    return (
        <div className='flex gap-4'>
            <div className="p-4 w-full bg-white rounded-lg shadow-lg">
                {['Twitter', 'Facebook', 'LinkedIn', 'Instagram'].map((platform) => (
                    <div className="mb-4" key={platform}>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            {platform}: {khachhang[platform]}
                        </label>
                        <input
                            type="text"
                            name={platform}
                            value={socialLinks[platform]}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>
                ))}
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-orange-300 hover:bg-orange-500 text-white rounded-lg"
                >
                    Cập nhật
                </button>
            </div>
            <div className="p-4 w-1/2 bg-white rounded-lg shadow-lg">
                <div className='grid grid-cols-2 grid-rows-2 gap-2'>
                    <a href={khachhang.Facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className='aspect-square text-blue-500 hover:bg-blue-500 hover:text-white border-2 rounded-xl flex items-center justify-center'>
                        <div className='scale-[2]'><FaFacebookF /></div>
                    </a>
                    <a href={khachhang.Twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className='aspect-square hover:bg-black hover:text-white border-2 rounded-xl flex items-center justify-center'>
                        <div className='scale-[2]'><FaXTwitter /></div>
                    </a>
                    <a href={khachhang.Instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group aspect-square border-2 rounded-xl flex flex-col items-center justify-center hover:bg-gradient-to-r hover:from-pink-500 hover:via-red-500 hover:to-yellow-500">
                        <div className="scale-[2] flex items-center">
                            <span className="flex items-center justify-center bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 group-hover:text-white">
                                <FaInstagramSquare className="-mt-4 " />
                            </span>
                        </div>
                        <span className="bg-clip-text text-xl text-transparent bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 group-hover:text-white">
                            Instagram
                        </span>
                    </a>
                    <a href={khachhang.LinkedIn}
                        target="_blank"
                        rel="noopener noreferrer" className='aspect-square p-4 hover:bg-blue-500 bg-white text-blue-500 hover:text-white  border-2 rounded-xl flex items-center justify-center'>
                        <div className='scale-[2]'><CiLinkedin /></div>
                    </a>
                </div>
            </div>
        </div>

    );
}

export default AccountSocialLinks;
