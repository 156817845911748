// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
    .use(HttpApi) // Sử dụng backend để tải file JSON
    .use(initReactI18next) // Kết nối với react-i18next
    .init({
        fallbackLng: 'en', // Ngôn ngữ mặc định
        lng: 'en', // Ngôn ngữ khởi đầu
        backend: {
            loadPath: '/locales/{{lng}}.json', // Đường dẫn tới file JSON
        },
        keySeparator: '.', // Khôi phục lại phân tách khóa bằng dấu '.'
        interpolation: {
            escapeValue: false, // Không cần escape (hỗ trợ React)
        },
    });

export default i18n;
