import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa'; // Import icon mặt trời và mặt trăng

const DarkModeToggle = () => {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        // Kiểm tra trạng thái dark mode từ localStorage khi tải trang
        const savedMode = localStorage.getItem('darkMode') === 'true';
        setDarkMode(savedMode);
        if (savedMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, []);

    const toggleDarkMode = () => {
        setDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', newMode);
            if (newMode) {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
            return newMode;
        });
    };

    return (
        <button
            onClick={toggleDarkMode}
            className="relative flex items-center justify-between w-14 h-8 bg-white rounded-full p-1 transition-all duration-300 dark:bg-gray-700"
        >
            {/* Đường di chuyển của nút */}
            <div
                className={`w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ${darkMode ? 'translate-x-6' : ''}`}
            >{
                    darkMode ? <FaMoon className="w-6 h-6 text-blue-500" /> : <FaSun className="w-6 h-6 text-yellow-500" />
                }

            </div>
        </button>
    );
};

export default DarkModeToggle;
