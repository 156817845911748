
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";

import StarRating from './StarRating';
import { TranslationContext } from '../locales/TranslationContext';
import { FaRegHeart } from "react-icons/fa";
import { IoShareSocialOutline } from "react-icons/io5";
import { CiYoutube } from "react-icons/ci";
import '../../css/edit.css'
import ApiContext from '../../api/ApiContext';
import car1 from '../../img/car/1.png'
import car4 from '../../img/car/4.png'
import Loading from '../Loading';

function ProdductDetail() {
    var productRating = 3;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [numOfGuests, setNumOfGuests] = useState("");
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [isOpenCart, setIsOpenCart] = useState(false);


    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };


    const handleInputChange = (event) => {
        setNumOfGuests(event.target.value);
    };

    const { id } = useParams(); // Lấy id từ địa chỉ URL
    const [khachhangs, setKH] = useState([]);
    const [product, setPro] = useState([]);
    const token = localStorage.getItem('token');
    const [formData, setFormData] = useState({
        TourId: '',
        KhachHangId: '',
        SoLuongKH: ''
    });
    console.log(product);
    const { products } = useContext(ApiContext);
    useEffect(() => {
        const product = products.find((product) => product.TourId === parseInt(id));
        if (product) {
            setPro(product);
        } else {
            setPro(null);
        }
    }, [products, id]);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_HOST}/api/khachhang/login`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` // Gửi token trong header
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setKH(data);
            })
            .catch((error) => console.error("Lỗi:", error));
    }, []);
    const handleAddToCart = async (e) => {
        e.preventDefault();

        if (token) {
            // Tạo formData cục bộ để đảm bảo đồng bộ
            const updatedFormData = {
                ...formData,
                TourId: product?.TourId || '', // Lấy TourId từ product
                KhachHangId: khachhangs?.IdKH || '', // Lấy KhachHangId từ khachhang
                SoLuongKH: numOfGuests || '', // Số lượng khách
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}/api/chitietgiohang`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedFormData),
                });

                const data = await response.json();

                if (response.ok) {
                    alert(data.message);

                    // Reset formData sau khi thêm thành công
                    setFormData({
                        TourId: '',
                        KhachHangId: '',
                        SoLuongKH: '',
                    });
                } else {
                    console.error('Lỗi khi thêm vé vào giỏ hàng:', data.error);
                    alert('Có lỗi xảy ra: ' + data.error);
                }
            } catch (error) {
                console.error('Lỗi khi thêm vé vào giỏ hàng:', error);
                alert('Đã xảy ra lỗi trong quá trình thêm vé.');
            }
        } else {
            setIsOpenCart(true)
            console.log("User already checked!");
        }
    };
    const handleToWishlist = async (e) => {
        const savedProducts = JSON.parse(localStorage.getItem('products')) || [];
        const isProductExist = savedProducts.some(item => item.id === id);
        if (!isProductExist) {
            // Thêm sản phẩm mới vào mảng
            savedProducts.push(product);
            // Loại bỏ các sản phẩm trùng dựa trên thuộc tính 'id'
            const uniqueProducts = savedProducts.filter((item, index, self) =>
                index === self.findIndex(p => p.TourId === item.TourId)
            );
            // Cập nhật lại localStorage với mảng không trùng lặp
            localStorage.setItem('products', JSON.stringify(uniqueProducts));
            alert("Product saved to wishlist!");
        }


    };
    // Tìm sản phẩm có id tương ứng
    if (!product) {
        return <Loading />;
    }
    return (
        <div className=''>
            <div className="flex flex-col p-4 pt-24 lg:py-24 lg:px-10 w-full overflow-hidden bg-orange-50 dark:bg-gray-700">
                <div className="w-full lg:w-1/2 py-2">
                    <div className="font-bold text-lg md:text-2xl dark:text-gray-100"> {product?.TourName || "Không khả dụng"}</div>
                    <div className="mt-2 text-sm md:text-base">
                        <div className='flex max-md:py-1'>
                            <StarRating rating={productRating} />
                            <div className="px-2 text-center dark:text-gray-100">{`(454 Reviews)`}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row mt-4">
                    <div className="w-full lg:w-3/4">
                        <div className='aspect-square'>
                            <img className='h-1/2 w-full object-cover' src={product.TourImage} alt='description' />
                            <div className=' h-1/3'>
                                <div className="bg-blue-200 h-1/2 rounded-xl my-4 flex overflow-hidden relative">
                                    {/* Phần chứa các ảnh phía trên */}
                                    <div className="absolute bottom-0 left-0 flex h-[110%] w-full overflow-hidden">
                                        <img className="h-full w-auto object-cover" src={car4} alt="description" />
                                        <img className="h-full w-auto object-cover" src={car4} alt="description" />
                                        <img className="h-full w-auto object-cover" src={car4} alt="description" />
                                    </div>

                                    {/* Phần chứa các ảnh trung tâm */}
                                    <div className="flex items-center justify-center w-full h-full relative animate-slideRight ">
                                        <div className="absolute">
                                            <img
                                                className="object-contain h-[20%] w-[20%]"
                                                src={car1}
                                                alt="description"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='font-bold text-lg md:text-2xl py-4 dark:text-gray-100'>Nội dung</div>
                                <div className='grid grid-flow-row gap-4 pb-10'>
                                    <div className='dark:text-gray-100'> {product?.TourDetail || "Không khả dụng"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-4'></div>
                    <div className="w-full lg:w-1/4 flex flex-col items-center space-y-4 ">
                        {/* Booking Section */}
                        <div className="bg-white w-full rounded-sm shadow-md p-4">
                            <div className='flex justify-between items-center'>
                                <div className="font-semibold text-lg mb-4">Booking</div>
                                <div className='font-semibold text-orange-500 text-xl  px-8'> {product.TourPrice
                                    ? <div className=''> {parseFloat(product.TourPrice).toLocaleString('vi-VN')} VND</div>
                                    : 'Giá không hợp lệ'}</div>
                            </div>

                            <div className="h-[1px] bg-gray-400 mb-4"></div>
                            <div className="flex flex-col p-6">
                                <span className="font-semibold">From</span>
                                <input
                                    id="datepicker-range-start"
                                    type="date"
                                    value={product.TourTime}
                                    onChange={handleStartDateChange}
                                    className="bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5 mt-2"
                                />
                                <span className="font-semibold pt-4">To</span>
                                <input
                                    id="datepicker-range-end"
                                    type="date"
                                    value={product.EndDate}
                                    onChange={handleEndDateChange}
                                    className="bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5 mt-2"
                                />
                                <span className="font-semibold pt-4">No. Of Guest</span>
                                <input
                                    value={numOfGuests}
                                    onChange={handleInputChange}
                                    className="bg-gray-50 border border-gray-300 text-sm rounded-lg w-full p-2.5 mt-2"
                                />
                            </div>
                            <div className="p-6 grid grid-flow-row gap-y-2">
                                <button className="bg-orange-300 w-full py-3 rounded-sm font-semibold text-white hover:bg-orange-400" onClick={handleAddToCart}>Confirm Booking</button>
                                <button
                                    onClick={() => handleToWishlist()}
                                    className="flex gap-2 items-center justify-center bg-white w-full py-3 rounded-sm font-semibold text-gray-500 border-2 hover:bg-orange-400 hover:text-white"
                                >
                                    <FaRegHeart />
                                    Save To Wishlist
                                </button>

                                <button className="flex gap-2 items-center justify-center bg-white w-full py-3 rounded-sm font-semibold text-gray-500 border-2 hover:bg-orange-400 hover:text-white">
                                    <IoShareSocialOutline />
                                    Share The Activity
                                </button>
                                <button onClick={() => setIsOpenVideo(true)} className="flex gap-2 items-center justify-center bg-white w-full py-3 rounded-sm font-semibold text-gray-500 border-2 hover:bg-orange-400 hover:text-white">
                                    <CiYoutube />
                                    Demo Video
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Form */}
            {isOpenVideo && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-4xl p-4 relative">
                        {/* Nút đóng */}
                        <button
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                            onClick={() => setIsOpenVideo(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>

                        {/* Tiêu đề */}
                        <h2 className="text-2xl font-bold text-gray-800 text-center mb-4">Video</h2>

                        {/* Video YouTube */}
                        <div className="flex justify-center mb-6">
                            <iframe
                                className="aspect-video w-full max-w-3xl rounded-lg"
                                src="https://www.youtube.com/embed/GOrwaPG_4E8?si=ca4rFgOSbDhYMpxF"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>

                        {/* Footer: Nút hành động */}
                        <div className="flex justify-center space-x-4">
                            <button
                                type="button"
                                className="px-6 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 focus:outline-none"
                                onClick={() => setIsOpenVideo(false)}
                            >
                                Đóng
                            </button>
                            <a
                                href="https://www.youtube.com/watch?v=GOrwaPG_4E8"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
                            >
                                Xem trên YouTube
                            </a>
                        </div>
                    </div>
                </div>

            )}
            {isOpenCart && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-4xl p-4 relative">
                        {/* Nút đóng */}
                        <button
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                            onClick={() => setIsOpenCart(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>

                        {/* Tiêu đề */}
                        <h2 className="text-2xl font-bold text-gray-800 text-center mb-4">Vui Lòng Đăng Nhập Để Mua Hàng</h2>


                    </div>
                </div>

            )}
        </div>


    );
}
export default ProdductDetail;
