import ApiContext from '../api/ApiContext';
import React, { useContext, useState } from 'react';
import Loading from '../component/Loading';

function Blog() {
    const { posts } = useContext(ApiContext); // Sử dụng dữ liệu từ Context
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [itemsToShow, setItemsToShow] = useState(3); // Số bài viết hiển thị ban đầu

    // Lọc bài viết theo tìm kiếm và danh mục
    const filteredPosts = posts.filter(post => {
        const matchesSearch = post.tieude.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory ? post.category === selectedCategory : true;
        return matchesSearch && matchesCategory;
    });

    // Hiển thị các bài viết hiện tại
    const currentPosts = filteredPosts.slice(0, itemsToShow);
    if (currentPosts.length <= 0) return <Loading />;
    return (
        <div className='p-4 md:p-12 lg:p-24 grid grid-flow-row overflow-hidden dark:bg-gray-700'>
            <div className='text-center text-3xl font-semibold pt-2 dark:text-orange-500'>Page Blog</div>
            <div className='flex items-center justify-center py-2 pb-6 gap-x-4 dark:text-gray-100'>
                <div className='text-orange-500'>Blog</div>
                <div className='w-[1px] h-full bg-gray-500'></div>
                Travel
            </div>
            <div className='w-full bg-contact rounded-2xl flex flex-col justify-end p-4 pt-40 overflow-hidden'>
                <button className='p-1 w-20 text-white text-sm rounded-xl bg-orange-500 mb-2'>Travel</button>
                <div className='text-white text-xl font-semibold'>Savor every precious moment</div>
                <div className='text-white text-xl font-semibold'>Indulge in the breathtaking beauty of nature</div>
                <div className='flex py-2 items-center justify-start w-1/5 gap-x-2'>
                    <img className=" w-10 h-10 border border-white aspect-square rounded-full object-cover" src='https://images.unsplash.com/photo-1728155469374-df8569326706?q=80&w=1827&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt='description' />
                    <div className='text-white font-semibold'>Admin</div>
                    <div className='text-white px-4'>22/11/2022</div>
                </div>
            </div>
            <div className="container mx-auto py-20">
                <div className="w-full flex flex-wrap flex-col sm:flex-row">
                    {/* Sidebar */}
                    <div className="w-full sm:w-2/5 order-1 sm:order-2">
                        <div className="p-6">
                            {/* Tìm kiếm */}
                            <div className="py-4">
                                <div className='dark:text-gray-100'>Tìm kiếm:</div>
                                <input
                                    type="text"
                                    placeholder="Tìm kiếm bài viết..."
                                    className="p-2 border rounded-md w-full"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                            </div>
                            {/* Danh mục */}
                            <div className="py-4">
                                <div className='dark:text-gray-100'>Danh mục</div>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {['Travel', 'Food', 'Adventure'].map((category, index) => (
                                        <button
                                            key={index}
                                            className={`${selectedCategory === category ? 'bg-orange-500 text-white' : 'bg-gray-200'
                                                } rounded-xl px-2 py-1`}
                                            onClick={() => setSelectedCategory(
                                                selectedCategory === category ? null : category
                                            )}
                                        >
                                            {category}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="py-4 dark:text-gray-100">
                                <div>Bài viết</div>
                                {posts.slice(0, 2).map(post => (
                                    <a href={`/detailblog/${post.Id}`} className="flex p-2 items-center hover:bg-orange-100 hover:text-black">
                                        <img
                                            className="w-16 h-16 aspect-square rounded-xl object-cover"
                                            src={post.image_path} alt="Alaska, Westminster to Greenwich River Thames"
                                        />
                                        <div className="px-2">
                                            <div className="font-semibold">
                                                {post.tieude}
                                            </div>
                                            <div>{new Date(post.ngaydang).toLocaleDateString('vi-VN')}</div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Nội dung chính */}
                    <div className="w-full sm:w-3/5 order-2 sm:order-1">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            {currentPosts.map(post => (
                                <a
                                    href={`/detailblog/${post.Id}`}
                                    className="h-full rounded-xl shadow-lg bg-white flex flex-col justify-between hover:bg-orange-100 group"
                                >
                                    <div className="h-full">
                                        <div className="rounded-t-xl overflow-hidden">
                                            <img
                                                className="rounded-t-xl aspect-video object-cover transform transition-transform duration-500 group-hover:scale-110"
                                                src={post.image_path} alt="Alaska, Westminster to Greenwich River Thames"
                                            />
                                        </div>
                                        <div className="p-4 flex flex-col justify-between">
                                            <div className="">Date: {new Date(post.ngaydang).toLocaleDateString('vi-VN')}</div>
                                            <div className="font-bold text-base">
                                                {post.tieude}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4">
                                        <div className="h-[1px] bg-gray-300 my-2"></div>
                                        <div className="relative w-auto overflow-hidden">
                                            <a
                                                href="#"
                                                className="text-sm group-hover:underline transition-all"
                                            >
                                                Read More
                                            </a>
                                            <div className="inset-y-0 left-0 bg-orange-500 w-0 h-[3px] group-hover:w-full transition-all duration-500 ease-in-out"></div>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                        {itemsToShow < filteredPosts.length && (
                            <button
                                className="w-full bg-white rounded-2xl my-6 border border-orange-400 text-center text-orange-400 py-2 hover:bg-orange-400 hover:text-white"
                                onClick={() => setItemsToShow(itemsToShow + 2)}
                            >
                                Tải thêm
                            </button>
                        )}
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Blog;
