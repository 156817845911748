import { IoPaperPlane } from "react-icons/io5";
import { useTranslation } from "react-i18next";  // Thêm import để sử dụng i18n
import NewRun from "../newRun";

function Home5() {
    const { t } = useTranslation();  // Khởi tạo hook i18n

    return (
        <div className="w-full h-auto md:h-[450px] px-5 md:px-20 py-10 relative dark:bg-gray-700">
            <div className='' data-aos='zoom-in-up' data-aos-delay="100">
                {/* Plane Icon */}
                <div className="w-12 h-12 md:w-14 md:h-14 rounded-full bg-orange-500 flex justify-center items-center absolute -top-4 mt-8 right-5 md:right-16 z-10">
                    <IoPaperPlane className="text-white text-3xl md:text-4xl" />
                </div>

                {/* Background with Circles */}
                <div className="bg-orange-100 overflow-hidden w-full h-[350px] md:h-80 rounded-xl relative">
                    <div className="relative flex justify-center items-center space-x-4">
                        <div className="absolute h-96 w-96 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-80 w-80 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-72 w-72 top-40 -left-40 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-80 w-80 top-36 -left-44 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-64 w-64 top-20 left-20 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-56 w-56 top-24 left-24 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-48 w-48 top-28 left-28 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-64 w-64 top-20 right-20 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-56 w-56 top-24 right-24 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-48 w-48 top-28 right-28 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-40 w-40 top-12 left-12 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-32 w-32 top-16 left-16 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-28 w-28 top-20 left-20 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-40 w-40 top-12 right-12 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-32 w-32 top-16 right-16 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-28 w-28 top-20 right-20 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-56 w-56 top-24 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-48 w-48 top-28 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-64 w-64 top-20 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-56 w-56 top-24 rounded-full border-4 border-orange-200"></div>
                        <div className="absolute h-48 w-48 top-28 rounded-full border-4 border-orange-200"></div>
                    </div>

                    {/* Content */}
                    <div className="w-full h-full flex flex-col justify-center items-center absolute top-0">
                        <div className="text-gray-500 font-semibold text-center text-lg md:text-2xl px-5 md:px-20">
                            {t('home.home20')}
                        </div>
                        <div className="flex mt-10 md:mt-20">
                            <input
                                type="text"
                                id="email"
                                className="w-40 sm:w-60 md:w-60 h-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 px-3"
                                placeholder={t('home.home21')}
                                required
                            />
                            <a href="/contact" className="ml-3 sm:ml-5 h-10 bg-gradient-to-b from-red-400 to-orange-400 hover:from-orange-400 hover:to-red-400 text-white font-bold py-2 px-4 rounded">
                                {t('home.home22')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Home5;
