import React, { useContext, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ApiContext from '../api/ApiContext';
import Loading from '../component/Loading';

function DetailBlog() {
    const { id } = useParams(); // Lấy id từ địa chỉ URL
    const { posts, accounts, khachhangs } = useContext(ApiContext); // Lấy dữ liệu từ Context
    const [post, setPost] = useState(null);
    const [account, setAccount] = useState(null);
    const [kh, setKhachHang] = useState(null);

    useEffect(() => {
        if (!posts || !accounts || !khachhangs) {
            console.log("Dữ liệu chưa sẵn sàng");
            return;
        }

        const foundPost = posts.find((blog) => blog.Id == id);
        if (foundPost) {
            const foundAccount = accounts.find((acc) => acc.ID == foundPost.AccountId);
            const foundKhachhang = khachhangs.find((kh) => kh.AccountID == foundPost.AccountId);


            setPost(foundPost);
            setAccount(foundAccount);
            setKhachHang(foundKhachhang ? foundKhachhang.TenKH : null); // Đặt giá trị
        }
    }, [posts, accounts, id, khachhangs]);


    if (!account || !kh || !post) {
        return <Loading />;
    }
    return (
        <div className='p-4 py-8 md:p-12 lg:p-24 grid grid-flow-row overflow-hidden dark:bg-gray-700'>
            <div className="preview-container" style={{ marginTop: '30px' }}>
                <h1 className='text-3xl font-bold'>{post.tieude}</h1> {/* Hiển thị tiêu đề bài viết */}
                <a href={`/blogprofile/${post.AccountId}`} className='flex items-center'>
                    <img
                        src={account.ProfilePicture}
                        alt="Avatar"
                        className="w-10 h-10 rounded-full object-cover"
                    />
                    <div className='p-4 font-semibold'>{kh}</div>
                </a>
                <div className='py-4'>Ngày đăng: {new Date(post.ngaydang).toLocaleDateString('vi-VN')}</div>
                <div className='h-[1px] bg-gray-600 my-4'></div>
                <div
                    className="preview-content scroll-my-0"
                    dangerouslySetInnerHTML={{ __html: post.noidung }} // Hiển thị nội dung với HTML
                    
                />
            </div>
        </div>
    );
}

export default DetailBlog;
